import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo"/>
                                </Link>

                                <Text>
                                    Оператор связи, телекоммуникационная и системная интеграция.
                                </Text>

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Clever Technology. Все права защищены.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Информация">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>О нас</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Контакты</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/team"}`}>Команда</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/blog-grid-without-sidebar"}`}>Блог</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Услуги">
            <List classes="widget-list">
            <LI><Link to={`${process.env.PUBLIC_URL + "/service/цифровые-каналы-связи?id=1"}`}>Аренда каналов</Link></LI>
            <LI><Link to={`${process.env.PUBLIC_URL + "/service/строительство-каналов-связи?id=2"}`}>Организация VPN</Link></LI>
            <LI><Link to={`${process.env.PUBLIC_URL + "/service/унифицированные-коммуникации?id=3"}`}>VOIP интеграция</Link></LI>
            <LI><Link to={`${process.env.PUBLIC_URL + "/service/вычислительные-ресурсы?id=4"}`}>Аренда ресурсов</Link></LI>
            <LI><Link to={`${process.env.PUBLIC_URL + "/service/виртуализация?id=5"}`}>Виртуализация</Link></LI>
            <LI><Link to={`${process.env.PUBLIC_URL + "/service/цифровизация?id=6"}`}>Цифровизация</Link></LI>
            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Контакты">
                            <address>
                                welcome@clever-tech.pro <br/>
                                (+7499) 705 06 69 <br/>
                                8 804 333 03 69
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

import React from 'react';

function HeaderConfig(props) {
    

    return (
        <div className="header-action mt-lg-3 text-right">
            <a href="tel:+78043330369" className="tel-no">8 804 333 03 69</a>
        </div>
    );
}

export default HeaderConfig;

import React,{Fragment} from 'react';

const ContactInfo = ({address}) => {
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address>
                    <span dangerouslySetInnerHTML={{__html: address}}/>
                </address>
            </div>
            <div className="member-social-icons mt-30">
            </div>
        </Fragment>
    );
};

export default ContactInfo;

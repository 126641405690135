import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Modal from 'react-awesome-modal';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
axios.defaults.baseURL = 'https://clever-tech.pro/fhandlem.php';

const recaptchaRef = React.createRef();
const modalStyle = {
textAlign: 'center',
marginTop: '6em' ,
};
export default class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        name: '',
        phone: '',
        email: '',
        text: '',
        mailSent: false,
        noHuman: false,
        empty: false,
        visible : false
        }
    }

    handleOpenModal = event => {
        this.setState({visible:true});
    }
    
    handleCloseModal = event => {
        this.setState({visible:false});
    }
    
    refresh = event => {window.location.reload()};
    
    handleSubmit = event => {
        const recaptchaValue = recaptchaRef.current.getValue();
        event.preventDefault(recaptchaValue);
        const user= {
        name: this.state.name,
        phone: this.state.phone,
        text: this.state.text,
        email: this.state.email,
        recaptcha: recaptchaValue
        };
        axios.post(`${axios.defaults.baseURL}`, { user })
        .then(result => {
                      if (result.data.sent) {
                      this.setState({ mailSent: true});
                      this.setState({ noHuman: false});
                      this.setState({ empty: false});
              this.handleOpenModal();
                      }
              if (result.data.nohuman) {
                      this.setState({noHuman: true});
                      this.setState({ mailSent: false});
                      this.setState({ empty: false});
              this.handleOpenModal();
              }
              if (result.data.empty) {
                            this.setState({empty: true });
                            this.setState({noHuman: false});
                            this.setState({mailSent: false});
              this.handleOpenModal();
              }
              })
    }

    render() {
        
        return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={this.handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                <div className="single-input-item">
                <label>
                <input type="text" id="name" name="name" required value={this.state.name} onChange={event=>this.setState({name: event.target.value})} placeholder="Ваше Имя" />
                </label>
                </div>
                </div>
                <div className="col-md-6">
                <div className="single-input-item">
                <label>
                <input type="phone" id="phone" name="phone" required value={this.state.phone} onChange={event=>this.setState({phone: event.target.value})} placeholder="Номер телефона"/>
                </label>
                </div>
                </div>
                <div className="col-md-6">
                <div className="single-input-item">
                <label>
                <input type="email" name="email" required value={this.state.email} onChange={event=>this.setState({email: event.target.value})} placeholder="Email"/>
                </label>
                </div>
                </div><ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Le6gv8UAAAAAFRh8BMUA220TUsU-IjPfE5ZpDCi"
                onChange={this.handleSubmit}
                />
                <div className="col-12">
                <div className="single-input-item">
                <div className="form-message"/>
                <textarea name="text" cols="30" rows="7" required value={this.state.text} onChange={event=>this.setState({text: event.target.value})} placeholder="Ваше сообщение"/>
                </div>
                </div>
                </div>
                <button className="btn-outline" type="submit">Отправить</button>
                <div>
                </div>
                </form>
                <Modal
                visible={this.state.visible}
                width="600"
                height="400"
                effect="fadeInUp"
                >{this.state.mailSent && <div className="col-lg-12"><div style={modalStyle}>
                <h2>Обращение отправлено!</h2><h5>Мы ответим Вам в ближайшее время. Спасибо!</h5><Link to={`${process.env.PUBLIC_URL + '/'}`}><button className="btn-outline">OK</button></Link></div></div>}
                {this.state.noHuman && <div className="col-lg-12"><div style={modalStyle}>
                <h2>Проверка на робота!</h2><h5>Вы не прошли проверку на робота, пожалуйста повторите</h5><button className="btn-outline" onClick={this.handleCloseModal}>ОК</button></div></div>}
                {this.state.empty && <div className="col-lg-12"><div style={modalStyle}>
                <h2>Ошибка!</h2><h5>Пожалуйста заполните форму еще раз. Если ошибка повторится, попробуйте позже</h5><button className="btn-outline" onClick={this.refresh}>ОК</button></div></div>}
                </Modal>
        </div>
                
                )
    }
}
Form.propTypes = {
config: PropTypes.object.isRequired
    };

import React from 'react';
const MemberInfo = ({teamMember}) => {
    const {profilePic,name,designation,bio,email} = teamMember;
    return (
        <div className="member-details-top">
            <div className="row">
                <div className="col-md-5">
                    <div className="member-pic mb-sm-35">
                        <img src={require('../../assets/img/' + profilePic)} alt={name}/>
                    </div>
                </div>

                <div className="col-md-7 ml-auto">
                    <div className="member-desc">
                        <h2>{name}</h2>
                        <h5>{designation}</h5>
                        <p dangerouslySetInnerHTML={{__html: bio}}/>

                        <div className="member-social-icons mt-25">
                        </div>

                        <div className="contact-info mt-25">
                            <p><strong>Email</strong> {email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberInfo;

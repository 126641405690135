import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about.jpg'

const PageService = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/page-header.jpg')}
                title="Наши услуги"
                content="Мы предоставляем комплексные решения связи и системной интеграции."
            />
            <PageAbout
                title={'Наши решения'}
                heading="Инивидуальный подход к каждому клиенту."
                thumb={ServiceThumb}
                content="При обращении наших клиентов, мы внимательно изучаем существующую инфраструктуру, выявляем возможность повышения эффективности коммуникаций и ИТ и формируем коммерческое предложение, руководствуясь принципами сокращения издержек и простого внедрения."
            />
            <Services classes="sm-top"/>
            <BrandLogo/>
            <Funfact classes="sp-top"/>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
    );
};

export default PageService;
